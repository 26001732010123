'use client';

import { useMember } from 'hooks/useMember';
import { memo } from 'react';

import { Dashboard } from 'components/Dashboard';
import { MyPortfolios } from 'components/MyPortfolios';

const Home = function () {
  const { isPbUser } = useMember();

  if (isPbUser) return <MyPortfolios />;

  return <Dashboard />;
};

export default memo(Home);
